import BaseInstance from "../base.instance";
import { NOTIFICATION_LIST } from "../constants";

const NotificationService = {
  getList(data) {
    return BaseInstance.post(NOTIFICATION_LIST, data);
  }
};

export default NotificationService;
